import React from "react";

import { Link } from "react-scroll";

import styles from "./Footer.module.scss";
import { useTranslation } from 'react-i18next';

function Footer() {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.Footer}>
      <div className={styles.Container}>
        <div className={styles.Warn}>
          <a target="_blank">{t('btnS.aviso')}</a>
        </div>
        <div className={styles.Links}>
          <a href="https://api.whatsapp.com/send?phone=5212226296999&text=Hola, estoy interesado en obtener más información sobre sus servicios. ¿Podrían proporcionarme más detalles?" target="_blank">WhatsApp</a>
          <a target="_blank">Facebook</a>
          <a target="_blank">Instagram</a>
          <a target="_blank">LinkedIn</a>
          <a target="_blank">X</a>
        </div>
      </div>
      <Link
        to="/"
        style={{ textAlign:'center'}}
        smooth={true}
        duration={1000}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        className="flex-1 text-right cursor-pointer text-nowrap"
      >
        Back to top
      </Link>
    </div>
  );
}

export default Footer;
