import React, { useState } from "react";
import { Link } from "react-scroll";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";

import logo from "../images/logo_blanco.png";
import { useTranslation } from 'react-i18next';



const effectSelector =
  "mx-2 inline-block relative after:content-[''] after:absolute after:w-full after:h-1 after:bg-white after:-bottom-2 after:left-0 after:scale-x-0 after:origin-center after:transition-transform hover:after:scale-100 hover:after:origin-center cursor-pointer";


const MenuItems = ({ className, itemClassName, onClose }) => {
  const [t, i18n] = useTranslation("global");
  const [optionSelect, selectOption] = useState("Esp");
  const [noOptionSelect, noSelectOption] = useState("Eng");

  const changeLanguage= (value) => {
    var select = document.getElementById('selectTranslate');
    select.style.position='relative';
    select.style.display='none';
    if(value === "Esp"){
      selectOption("Esp");
      noSelectOption("Eng");
      i18n.changeLanguage("es")
    }else{
      selectOption("Eng");
      noSelectOption("Esp");
      i18n.changeLanguage("en")
    }
  }

  return (
    <ul className={className}>
      <li className={effectSelector} >
        <Link
          style={{ fontSize:'19px',fontFamily:'Coolvetica', fontWeight:'100'}}
          to="about"
          className={`hover:text-gray-300" ${itemClassName}`}
          smooth={true}
          duration={1000}
          onClick={onClose}
        >
          {t("navbar.about")}
        </Link>
      </li>
      <li className={effectSelector}>
        <Link
          style={{ fontSize:'19px',fontFamily:'Coolvetica', fontWeight:'100'}}
          to="applications"
          className={`hover:text-gray-300" ${itemClassName}`}
          smooth={true}
          duration={1000}
          onClick={onClose}
        >
          {t("navbar.Solutions")}
        </Link>
      </li>
      <li className={effectSelector}>
        <Link
          style={{ fontSize:'19px',fontFamily:'Coolvetica', fontWeight:'100'}}
          to="contact"
          className={`hover:text-gray-300" ${itemClassName}`}
          smooth={true}
          duration={1000}
          onClick={onClose}
        >
           {t("navbar.Contact")}
        </Link>
      </li>
      <li >
        <div onClick={()=>{document.getElementById('selectTranslate').style.display='block'; document.getElementById('selectTranslate').style.position='absolute'}} style={{cursor:'pointer'}}>
          <span style={{ fontSize:'19px',fontFamily:'Coolvetica', fontWeight:'100'}}
          >{optionSelect}<div style={{ rotate: "90deg",position:'relative', right:'-39px', top:'0px', height:'0px'}}>&#8250;</div></span>
        </div>
        <div style={{display:'none',marginTop:'15px', marginLeft:'-7px'}} id="selectTranslate">
          <div className={effectSelector}>
            <button style={{marginBottom:'-20px', fontSize:'19px',fontFamily:'Coolvetica', fontWeight:'100'}} onClick={()=> {changeLanguage(noOptionSelect)}}>{noOptionSelect} </button>
          </div>
        </div>
      </li>
      <li></li>
    </ul>
  );
};

const SideMenu = ({ open, onClose }) => {
  return (
    <div
      className={`sm:hidden absolute top-0 -left-96 bg-pink-600	w-full max-w-sm h-screen flex flex-col origin-left transition-transform ${
        open && "translate-x-full"
      }`}
    >
      <img
        src={logo}
        alt="Branimax"
        className="w-44 md:w-52 relative -top-12 left-8"
      />
      <button className="absolute right-3 top-6 text-white" onClick={onClose}>
        <IoCloseSharp className="text-4xl" />
      </button>
      <MenuItems
        className="flex flex-col gap-14 px-10 relative -top-24"
        itemClassName="text-5xl font-bold text-white"
        onClose={onClose}
      />
    </div>
  );
};

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full fixed top-0 z-50">
      <div className="flex flex-row justify-between h-20 items-center px-5">
        <div className="text-lg font-semibold">
          <Link
            to="/"
            target=""
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <img src={logo} alt="Branimax" className="w-44 md:w-52" />
          </Link>
        </div>

        <button className="sm:hidden" onClick={() => setOpen(!open)}>
          <IoMenu className="text-white text-3xl" />
        </button>

        <MenuItems className="flex text-white font-bold gap-6 hidden sm:flex" />

        <SideMenu open={open} onClose={() => setOpen(false)} />
      </div>
    </nav>
  );
};

export default React.memo(Navbar);
