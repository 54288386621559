import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import video from "../images/video.mp4";
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const [t, i18n] = useTranslation("global");

  return (
    <div
      id="hero"
      className="relative text-white overflow-hidden"
      style={{ height: "calc(100vh)" }}
    >
      <video
        autoPlay
        loop
        muted
        className="hidden md:flex absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover z-0"
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="absolute bottom-1 mb-20 px-5 md:px-12 w-full z-10">
        <h1 className="text-5xl md:text-7xl font-bold">
          {t("home.header1")}
          <br />
          {t("home.header2")}
        </h1>
        <div className="flex flex-col md:flex-row justify-between items-center pt-10">
          <p className="text-2xl font-bold">
          {t("home.Subtitle")}
          </p>
          <button
            onClick={() => setOpen(true)}
            className="md:flex hidden border-2 rounded-lg px-8 py-2 border-white font-bold hover:bg-white hover:text-purple-950 text-nowrap"
          >
            {t('btnS.seemore')}
          </button>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        className="flex justify-center items-center h-full"
      >
        <video className="max-w-4xl w-full" controls buffered={3000} autoPlay>
          <source src={video} type="video/mp4" />
        </video>
      </Modal>
    </div>
  );
};

export default HeroSection;
