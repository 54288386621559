import React from "react";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Footer from "../pages/Footer";

import styles from "./Contact.module.scss";
import DotLoader from "react-spinners/DotLoader";
import { useTranslation } from 'react-i18next';

import xImg from "../images/x.png"
const Contact = () => {
  const regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  const [t, i18n] = useTranslation("global");

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [invalidName, setInvalidName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [muestraSpinner, setmuestraSpinner] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [invalidEmailRegex, setInvalidEmailRegex] = useState(false);

  useEffect(()=>{
    console.log(muestraSpinner);
  },[muestraSpinner]);

  const handleOnChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form, "nana", form.phone.length);
    form.name === "" ? setInvalidName(true) : setInvalidName(false); 
    form.email === "" ? setInvalidEmail(true) : setInvalidEmail(false); 
    form.phone === "" ? setInvalidPhone(true) : setInvalidPhone(false);
    form.message === "" ? setInvalidMessage(true) : setInvalidMessage(false); 
    regexEmail.test(form.email) ? setInvalidEmailRegex(false) : setInvalidEmailRegex(true);
    form.name !== "" && form.email !== "" && form.phone !== "" && form.message !== "" && regexEmail.test(form.email) ? setmuestraSpinner(true): setmuestraSpinner(false);
    console.log(invalidEmailRegex);
    console.log(regexEmail.test(form.email));
    setTimeout(() => {
      if(form.name !== "" && form.email !== "" && form.phone !== "" && form.message !== "" && regexEmail.test(form.email)){
     
        console.log("envia", muestraSpinner);
        fetch('https://sheetdb.io/api/v1/8w06whlivfryb', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: [
                {
                    'id': "INCREMENT",
                    'nombre': form.name,
                    'email': form.email,
                    'telefono': form.phone,
                    'mensaje' : form.message
                }
            ]
        })
    })
      .then((response) => response.json())
      .then((data) => { 
        console.log(data); 
        setmuestraSpinner(false); 
        form.email = ""; 
        form.message= ""; 
        form.name = ""; 
        form.phone = "";
        document.getElementById('modalGracias').style.display ="block";
        }
      );
      }
      
    }, 2000);
    

  };

  const cerrarModal =  () => {
    document.getElementById('modalGracias').style.display ="none";
  }

  const commonProps = {
    onChange: handleOnChange,
    variant: "standard",
    fullWidth: true,
    InputLabelProps: {
      style: {
        fontSize: "2.2rem",
        fontWeight: 800,
        color: "white",
        position: "absolute",
        top: -13,
        fontFamily:
          'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      },
    },
    inputProps: {
      maxLength: "150",
      style: {
        fontSize: "25px",
        fontWeight: 800,
        color: "white",
        fontFamily:
          'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        borderBottom: "4px solid white",
      },
    },
  };

  return (
    <div>
    <div id="modalGracias" style={{display:'none'}}>
      <div className={styles.containerMensaje}>
        <div>  <img src={xImg} alt="Logo X" className={styles.xImg} onClick={()=> {cerrarModal()}}/></div>  
        <div className={styles.containertxt}> 
          <p className={styles.txtMensaje}>{t('contact.modalTitle')}</p> 
          <p className={styles.txtMensaje}>{t('contact.modaltxt')}</p>
        </div>
        <div style={{marginTop:'65px'}} className={styles.Actions_modal}>
        <button onClick={()=> {cerrarModal()}} className={styles.btnMensaje}>
            OK 
        </button>
        </div>
      </div>
    </div>
    <div className={styles.Contact} id="contact">
      <h1 className={styles.Title}>{t('contact.title')}</h1>
      <h2 className={styles.Description}>
        {t('contact.txt1')}
      </h2>
      <h2 className={styles.Description}>
        {t('contact.txt2')}
      </h2>
      <br/>
      <div className={styles.Form}>
        <TextField
          label={t('contact.name')}
          name="name"
          value={form.name}
          {...commonProps}
        />
        {invalidName && <p className={styles.campoObligatorio}>El campo es obligatorio.</p>}
        <TextField
          label={t('contact.email')}
          name="email"
          value={form.email}
          {...commonProps}
        />
        {invalidEmail && <p className={styles.campoObligatorio}>El campo es obligatorio.</p>}
        {(invalidEmailRegex && !invalidEmail) && <p className={styles.campoObligatorio}>Ingrese un Email valido</p>}
        <TextField
          label={t('contact.phone')}
          name="phone"
          type="number"
          value={form.phone}
          {...commonProps}
        />
        {invalidPhone && <p className={styles.campoObligatorio}>El campo es obligatorio.</p>}
        <TextField
          multiline
          rows={2}
          maxRows={4}
          label={t('contact.message')}
          name="message"
          value={form.message}
          {...commonProps}
        />
        {invalidMessage && <p className={styles.campoObligatorio}>El campo es obligatorio.</p>}
        <div className={styles.Actions}>
          { 
            muestraSpinner ? 
            <div style={{marginTop:'15px'}}>
              <DotLoader color={'#DB2777'} size={40} loading={muestraSpinner} />
            </div>
            :
            <button
              onClick={handleSubmit}
              className="bg-pink-600 md:flex hidden text-white rounded-full px-8 py-2 font-bold hover:bg-white hover:text-pink-600 text-nowrap"
            >
              ENVIAR
            </button>
          }
        </div>
      </div>
      <Footer />
    </div>
    </div>
  );
};

export default Contact;
