import React from "react";
import classNames from "classnames";
import useAnimatedInView from "../hooks/useAnimatedView";
import { motion } from "framer-motion";

import AboutImage from "../images/about.jpg";
import { useTranslation } from 'react-i18next';

import styles from "./About.module.scss";

const About = () => {
  const { ref, controls } = useAnimatedInView();
  const [t, i18n] = useTranslation("global");

  return (
    <div ref={ref} id="about">
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{ duration: 0.5, delay: 0.25 }}
        className={styles.About}
      >
        <div className={styles.Section_Nosotros}>
          <h2 className={styles.Title}>{t("about.title")}</h2>
          <div style={{marginBottom:"15px"}}>
            <span className={styles.Description}>{t("about.titletxt1")} </span>
            <span className={styles.Descriptiontxt1}>{ t("about.txt1")}</span>
          </div>
          <div style={{marginBottom:"15px"}}>
            <span className={styles.Description}>{t("about.titletxt2")} </span>
            <span className={styles.Descriptiontxt1}>{ t("about.txt2")}</span>
          </div>
          <div style={{marginBottom:"15px"}}>
            <span className={styles.Description}>{t("about.titletxt2")} </span>
            <span className={styles.Descriptiontxt1}>{ t("about.txt2")}</span>
          </div>
        </div>
        <div className={styles.Section}>
          <div className={styles.Image} />
        </div>
        {/* <div className="flex flex-col md:flex-row justify-between">
          <div className="flex-1 flex flex-col justify-end	md:justify-center text-white px-5 md:px-12">
            <h2 className="text-5xl md:text-7xl font-bold"></h2>

            <p className="text-1xl md:text-2xl font-bold pt-3 md:pt-28 pb-8 leading-relaxed">
              
            </p>
          </div>
          <div
            className="flex-1"
            style={{ backgroundImage: `url(${AboutImage})` }}
          >
            sdfsdf
          </div>
        </div> */}
      </motion.div>
    </div>
  );
};

export default About;
