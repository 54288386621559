import React, { useState } from "react";
import { styles } from "../styles";
import Explaining from "./Explaining";
import ChatEngine from "./ChatEngine";

const Index = ({ visible }) => {
  const [chat, setChat] = useState(false);

  const handleStartChat = () => {
    setChat(true);
  };

  return (
    <div
      className="transition-5"
      style={{
        ...styles.supportWindow,
        ...{ opacity: visible ? "1" : "0", zIndex: visible ? "10000" : "-100" },
      }}
    >
      {visible && !chat && (
        <Explaining
          handleStartChat={handleStartChat}
          visible={chat === false}
        />
      )}
      {chat && <ChatEngine />}
    </div>
  );
};

export default Index;
