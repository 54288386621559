import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import Navbar from "./pages/Navbar";
import HeroSection from "./pages/HeroSection";
import About from "./pages/About";
import Applications from "./pages/Aplications";
import Contact from "./pages/Contact";
import SupportEngine from "./pages/SupportEngine/SupportEngine";
import { ThemeProvider } from "@mui/material/styles";
import Popup from 'reactjs-popup';

import theme from "./theme";
import "./index.css";

import {I18nextProvider} from 'react-i18next';
import i18next from "i18next";
import global_esp from "./translations/esp/global.json";
import global_eng from "./translations/eng/global.json";


i18next
.init({
  interpolation: {escapeValue: false},
  lng: "es",
  resources: {
    es: {
      global: global_esp
    },
    en: {
      global: global_eng
    }
  }
})

function App() {
  return (
    <div
      className="w-screen min-h-screen"
      style={{ background: "linear-gradient(238deg, #030840, #4f065c)" }}
    >
      <Navbar />
      <HeroSection />
      <About />
      <Applications />
      <ThemeProvider theme={theme()}>
        <Contact id="contact" />
      </ThemeProvider>
      <SupportEngine />
    </div>
  );
}

const element = document.getElementById("root");
ReactDOM.createRoot(element).render(
<I18nextProvider i18n={i18next}>
  <App />
</I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
