import React, { useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import axios from "axios";

import styles from "./ChatEngine.module.scss";

const ChatEngine = (props) => {
  const [question, setQuestion] = useState("");
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSendQuestion = async () => {
    setIsLoading(true);
    const requestData = {
      question: question,
      id: 7,
    };
    await axios
      .post(`https://servidorscarlett.com/post-texto`, requestData)
      .then((res) => {
        if (res.status === 200) {
          setConversations([
            ...conversations,
            { question: question, answer: res.data.response },
          ]);
          setQuestion("");
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err.message);
        setIsLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Esto previene el comportamiento por defecto del "Enter"
      event.stopPropagation(); // Esto detiene la propagación del evento
      handleSendQuestion();
    }
  };

  const handleScroll = (event) => {
    event.stopPropagation();
    // ... cualquier otra lógica que necesites
  };

  return (
    <div onKeyDown={handleKeyDown} className={styles.ChatEngine}>
      <div className="py-2 px-4">¡Pregúntame lo que quieras!</div>

      <div className="border-2 flex-1 p-2 overflow-auto">
        <div className="snap-end">
          {conversations.map((conversation, index) => (
            <div key={index}>
              <div
                className="text-right my-3 p-4 ml-32 text-white rounded-tl-2xl rounded-bl-2xl	rounded-br-2xl"
                style={{ backgroundColor: "#4F065C" }}
              >
                <p>{conversation.question}</p>
              </div>
              <div
                className="text-left p-2 mr-32 text-white rounded-tr-2xl rounded-bl-2xl rounded-br-2xl"
                style={{ backgroundColor: "#B74BC6" }}
              >
                <p>{conversation.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-slate-50">
        {conversations.length == 0 && !isLoading && (
          <div className="text-center font-light italic mt-2 py-2">
            Send a message...
          </div>
        )}

        {isLoading && (
          <div className="text-center font-light italic animate-pulse py-2">
            Gimme a few seconds...
          </div>
        )}
      </div>

      <div className="bg-white w-auto px-4 py-2 flex">
        <input
          className="flex-1 focus:outline-none"
          type="text"
          value={question}
          onChange={handleQuestionChange}
          placeholder="Escribe tu pregunta aquí..."
        />
        <button
          className="bg-slate-700 w-9 h-9 rounded "
          onClick={handleSendQuestion}
        >
          <TelegramIcon className="text-white" />
        </button>
      </div>
    </div>
  );
};

export default ChatEngine;
