import React from "react";

import Card from "./Card";

import styles from "./Aplications.module.scss";
import { useTranslation } from 'react-i18next';

const About = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div id="applications" className={styles.Applications}>
      <h2 className={styles.Title}>{t("solutions.title")}</h2>
      <div className={styles.Container}>
        <div>
          <Card
            title={t("solutions.title1")}
            description= {t("solutions.txt1")}
          />
          <Card
            title={t("solutions.title2")}
            description= {t("solutions.txt2")}
          />
          <Card
             title={t("solutions.title3")}
             description= {t("solutions.txt3")}
          />
        </div>
        <div>
          <Card
            title={t("solutions.title4")}
            description= {t("solutions.txt4")}
          />
          <Card
            title={t("solutions.title5")}
            description= {t("solutions.txt5")}
          />
          <Card
             title={t("solutions.title6")}
             description= {t("solutions.txt6")}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
