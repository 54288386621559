import React from "react";

import styles from "./Card.module.scss";

function Card({ title, description, action }) {
  return (
    <div className={styles.Card}>
      <div className="flex-1 w-full">
        <h2 className=" text-white font-bold mb-1 md:mb-4 w-full" style={{fontSize:'23px'}}>
          {title}
        </h2>
        <p className="text-white md:mb-6 tracking-normal w-full" style={{fontSize:'17px'}}>
          {description}
        </p>
      </div>
      <button style={{width:'150px', paddingLeft:'3rem', height:'35px', paddingTop:'0.2rem', paddingBottom:'0.5rem'}} 
      className="md:flex hidden border-2 rounded-3xl border-white text-white font-bold hover:bg-white hover:text-rose-500 text-nowrap">
        CLICK
      </button>
    </div>
  );
}

export default Card;
