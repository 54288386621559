import { createTheme } from "@mui/material/styles";

const theme = () =>
  createTheme({
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              border: "none",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          inputMultiline: {
            position: "relative",
            bottom: "-15px",
          },
        },
      },
    },
  });

export default theme;
