import { useState } from "react";
import React from 'react';
import { styles } from "../styles";
import { LoadingOutlined } from "@ant-design/icons";
import Avatar from "../Avatar";

const Explaining = (props) => {
    const [loading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        props.handleStartChat(); // Llamamos a la función handleStartChat del componente padre para iniciar la conversación.
    };

    return (
        <div
            style={{
                ...styles.emailFormWindow,
                ...{
                    height: props.visible ? "100%" : "0%",
                    opacity: props.visible ? "1" : "0",
                }
            }}
        >
            <div style={{ height: '0px' }}>
                <div style={styles.stripe} />
            </div>

            <div
                className="transition-5"
                style={{
                    ...styles.loadingDiv,
                    ...{
                        zIndex: loading ? '10' : '-1',
                        opacity: loading ? '0.33' : '0',
                    }
                }}
            />
            <LoadingOutlined className="transition-5"
                style={{
                    ...styles.loadingIcon,
                    ...{
                        zIndex: loading ? '10' : '-1',
                        fontSize: '82px',
                        opacity: loading ? '1' : '0',
                        top: 'calc(50% - 41px)',
                        left: 'calc(50% - 41px)',
                    }
                }}
            />

            <div style={{ position: 'absolute', height: '100%', width: '100%', textAlign: 'center' }}>
                <Avatar
                    style={{
                        position: 'relative',
                        left: 'calc(50% - 44px)',
                        top: '10%'
                    }}
                />
                <div style={styles.topText}>
                    Feel free to ask anything about Branimax<br />  <span role="img" aria-label="heart">❤️</span> !
                </div>
                <div style={styles.bottomText}>
                    <button onClick={handleSubmit} className="rounded-full border p-4 hover:bg-black hover:text-white"> Lets Chat!</button>
                </div>
            </div>
        </div>
    );
};

export default Explaining;
