// hooks/useAnimatedInView.js
import { useEffect ,useRef} from 'react';
import { useInView } from 'framer-motion';
import { useAnimation } from 'framer-motion';

const useAnimatedInView = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [controls, isInView]);

  return { ref, controls };
};

export default useAnimatedInView;